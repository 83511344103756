import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import esquire from "@/types/clients/esquire";
import LogOut from "@/types/clients/esquire/requests/auth/log-out";
import {getCurrentInstance} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppToolbar',
  setup(__props) {

const instance = getCurrentInstance();
const router = instance?.appContext.config.globalProperties.$routerUtils;

async function logOut() {
  const request = new LogOut(esquire);
  await request.send();
  await router.routeTo("auth");

}

return (_ctx: any,_cache: any) => {
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, { color: "primary" }, {
    append: _withCtx(() => [
      _createVNode(_component_v_menu, null, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_btn, _mergeProps({ icon: "mdi-dots-vertical" }, props), null, 16)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { onClick: logOut }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        "append-icon": "mdi-logout",
                        style: {"width":"100%"}
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" Log out ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar_title, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Be-Housing CallCenter App")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})